<template>
  <section class="hero is-fullheight">
    <ModalSubLineChild
      v-if="activeModalSubLineChild"
      :active="activeModalSubLineChild"
      :idSelect="idSubLineSelect"
      @set-data-add-sub-line="addChildSubLine"
      @close-modal="deactivateModalAddSubLineChild"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="columns is-multiline">
                  <div class="column is-7">
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-8">
                        <label class="label mt-3">Línea</label>
                        <input
                          class="input"
                          type="text"
                          placeholder="Línea"
                          v-model="parentLineName"
                          name="parentLineName"
                          v-validate="'required'"
                          data-vv-as="de la línea"
                        >
                        <span v-show="vverrors.has('parentLineName')" class="help is-danger">{{ vverrors.first('parentLineName') }}</span>
                      </div>

                      <div class="column is-12 mb-6">
                        <label class="label">Sub línea</label>
                        <div class="columns is-multiline is-mobile">
                          <div class="column is-8">
                            <input
                              class="input"
                              type="text"
                              placeholder="Sub línea"
                              v-model="subLineTemp"
                              data-vv-as="de la Sub línea"
                            >
                          </div>
                          <div class="column is-2">
                            <button class="button is_green" @click="addSubLineToArray">
                              +
                            </button>
                          </div>
                        </div>
                        <span class="help is-danger" v-show="hasErrorNotSubLine">La Línea debe tener al menos una Sub línea</span>
                        <span class="help is-danger" v-show="hasErrorNotSubLineChild">La(s) Sub línea(s) debe(n) tener al menos un elemento</span>
                      </div>

                      <template v-for="subLine in subLines">
                        <div class="column is-12" :key="`parent-subline-${subLine.id}`">
                          <div class="columns is-multiline is-vcentered is-mobile">
                            <!-- sublineas -->
                            <div class="column is-5-desktop is-10-mobile">
                              <p class="label">{{ subLine.name }}</p>
                            </div>
                            <div class="column is-1-desktop is-2-mobile is-flex is-justify-content-center is-align-items-center">
                              <img
                                class="icon_delete"
                                src="@/assets/icon/trash_icon.png"
                                alt="Borrar"
                                @click="removeSubLine(subLine.id)"
                              >
                            </div>
                            <div class="column is-6-desktop is-12-mobile">
                              <p class="emphasis_link" @click="activeModalAddSubLineChild(subLine.id)">
                                Agregar sub línea de producto
                              </p>
                            </div>
                            <!-- sublineas childs -->
                            <template v-if="subLine.childSubLine.length > isEmpty">
                              <template v-for="child in subLine.childSubLine">
                                <div class="column is-12" :key="`child-subline-${child.id}`">
                                  <div class="columns is-multiline is-mobile">
                                    <div class="column is-6 pl-6">
                                      <p>{{ child.name }}</p>
                                    </div>
                                    <div class="column is-2">
                                      <img
                                        class="icon_delete_item"
                                        src="@/assets/icon/cancel_icon.png"
                                        alt="Borrar"
                                        @click="removeChildSubLine(subLine.id, child.id)"
                                      >
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- Columna para la imagen -->
                  <div class="column is-3">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <card-upload-file
                          :whitBorder="true"
                          titleCard="Imagen de portada(Opcional)"
                          :previewImage="previewImageCover"
                          :setFile="setFileCover"
                          :modeEdit="modeEdit"
                          validate="ext:jpeg,jpg,png|size:1000"
                          accept=".jpeg,.jpg,.png,"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Columna para el estatus -->
                  <div class="column is-2">
                    <label class="label mt-3">Estatus</label>
                    <span>
                      <input
                        id="switchStatus"
                        type="checkbox"
                        class="switch is-rounded is-small"
                        :checked="status ? 'checked' : ''"
                        v-model="status"
                        name="switchStatusStatus"
                      >
                      <label for="switchStatus"></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LineMainAdd',
  inject: ['$validator'],
  components: {
    CardUploadFile: () => import('@/components/UI/CardUploadFile.vue'),
    ModalSubLineChild: () => import('@/components/Products/Lines/ModalSubLineChild.vue')
  },
  data () {
    return {
      isEmpty: 0,
      modeEdit: true,
      currentId: 0,
      previewImageCover: null,
      parentLineName: null,
      subLineTemp: null,
      subLines: [],
      status: false,
      activeModalSubLineChild: false,
      idSubLineSelect: null,
      hasErrorNotSubLine: false,
      hasErrorNotSubLineChild: false
    }
  },
  props: {
    line: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    addSubLineToArray () {
      if (this.subLineTemp) {
        this.hasErrorNotSubLine = false
        this.currentId += 1
        const defaultSubLine = {
          id: this.currentId,
          name: this.subLineTemp,
          active: true,
          childSubLine: []
        }
        this.subLines.push(defaultSubLine)
        this.subLineTemp = null
      }
    },
    removeSubLine (subLineId) {
      const removeIndex = this.subLines.map(item => item.id).indexOf(subLineId)
      this.subLines.splice(removeIndex, 1)
    },
    activeModalAddSubLineChild (id) {
      this.idSubLineSelect = id
      this.activeModalSubLineChild = true
    },
    deactivateModalAddSubLineChild () {
      this.idSubLineSelect = null
      this.activeModalSubLineChild = false
    },
    async addChildSubLine ({ sublineId, subProductLine }) {
      this.hasErrorNotSubLineChild = false
      await this.subLines.map(item => {
        if (item.id === sublineId) {
          let idTemp = 0
          if (item.childSubLine.length > this.isEmpty) {
            const maxId = item.childSubLine.reduce((prev, current) => {
              if (current.id > prev.id) { return current } else { return prev }
            })
            idTemp = maxId.id
          }
          const newChild = {
            id: idTemp += 1,
            name: subProductLine,
            active: true
          }
          item.childSubLine.push(newChild)
        }
      })
      this.deactivateModalAddSubLineChild()
    },
    async removeChildSubLine (parentId, childId) {
      const parentIndex = this.subLines.map(item => item.id).indexOf(parentId)
      const removeIndex = this.subLines[parentIndex].childSubLine.map(item => item.id).indexOf(childId)
      this.subLines[parentIndex].childSubLine.splice(removeIndex, 1)
    },
    async setFileCover (event) {
      if (event) {
        const [file] = event.target.files
        const imageFile = { key: event.target.name, file }
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageCover = e.target.result
          }
          this.$emit('set-image', imageFile)
        }
      }
    },
    async validateSubLines () {
      this.hasErrorNotSubLine = false
      this.hasErrorNotSubLineChild = false
      if (this.subLines.length > this.isEmpty) {
        const subLinesfilled = this.subLines.map(child => {
          if (child.childSubLine.length > this.isEmpty) { return true } else { return false }
        })
        if (subLinesfilled.includes(false)) {
          this.hasErrorNotSubLineChild = true
          return false
        } else {
          return true
        }
      } else {
        this.hasErrorNotSubLine = true
        return false
      }
    },
    async getLocalData () {
      const dataSet = {
        parentLineName: this.parentLineName,
        subLinesList: this.subLines,
        active: this.status
      }
      return dataSet
    }
  },
  beforeMount () {
    this.previewImageCover = this.line.coverImage
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }

  .icon_delete {
    cursor: pointer;
    width: 22px;
  }
  .icon_delete_item {
    cursor: pointer;
    width: 18px;
  }
  .icon_delete:hover,
  .icon_delete_item:hover {
    filter: invert(41%) sepia(55%) saturate(5359%) hue-rotate(327deg) brightness(100%) contrast(102%);
  }
  .emphasis_link {
    cursor: pointer;
    font-size: 16px;
  }
</style>
